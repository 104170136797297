.topo{
	background-color: #fff;

	.banner-fixo{
		width: 100%;
		max-width: 590px;
		margin-right: auto;
		margin-left: auto;
	}

	.main-navigation{
		.menu{
			list-style-type: none;
			margin-bottom: 0;
			padding-left: 0;
			font-family: $ws-bold;
			color: #000;
		}
	}
	// .main-navigation

	@include media-breakpoint-only(xl) {
		.mbl-controls{
			display: none;
		}
		.banner-fixo{
			margin-bottom: -80px;
		}
		.main-navigation{
			width: 100%;
			max-width: 1140px;
			margin-right: auto;
			margin-left: auto;
			padding-left: 15px;
			padding-right: 15px;

			.bloco-superior{
				display: flex;
				align-items: flex-end;
				justify-content: space-between;

				.logo,
				.autor{
					width: 160px;
					text-align: center;
				}

				.contatos-topo{
					flex-grow: 1;
					border-bottom: 4px solid #ededed;
					display: flex;
					justify-content: space-between;
					font-size: 14px;
					color: #000;
					padding-bottom: 10px;

					.msg{
						flex-grow: 1;
					}
					.midia + .midia{
						margin-left: 15px;
					}
					.midia{
						display: flex;
						align-items: center;

						a:hover{
							text-decoration: underline;
						}

						i.fas{
							margin-right: 5px;
						}
					}
				}
			}
			.menu{
				font-size: 13px;
				display: grid;
				grid-template-columns: minmax(min-content, max-content) minmax(10px, auto)
				minmax(min-content, max-content) minmax(10px, auto)
				minmax(min-content, max-content) minmax(10px, auto)
				minmax(min-content, max-content) minmax(10px, auto)
				minmax(min-content, max-content) minmax(10px, auto)
				minmax(min-content, max-content) minmax(10px, auto)
				minmax(min-content, max-content) minmax(10px, auto)
				minmax(min-content, max-content) minmax(10px, auto)
				minmax(min-content, max-content);

				padding: 25px 0;

				& > li {
					margin:0 auto;
					display: flex;
					$d: 1;
					text-align: center;

					@for $i from 1 through 9 {
						&:nth-child(#{$i}) {
							grid-column: #{$d} / #{$d+1};
						}

						$d: $d +2;
					}
				}

				.active a{
					color: $azul-claro;
				}

				a{
					display: flex;
					padding: 5px;
					transition: all 0.3s linear;
					justify-content: center;
					align-items: center;

					&:hover{
						color: $azul-claro;
					}
				}
			}
		}
		//.main-navigation
	}
	//DESKTOP

	@include media-breakpoint-down(lg) {
		.mbl-controls{
			padding: 10px 0;
			.container{
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;

				.botao-mobile{
					flex-grow: 1;
					display: flex;
					justify-content: flex-end;

					.btn-menu{
						padding: 15px 0 15px 25px;
						font-size: 16px;
						cursor: pointer;
					}
				}
				figure{
					margin-right: 10px;
					img{
						width: 70px;
					}
				}
			}
		}
		// .mbl-controls
		.main-navigation{
			position: fixed;
			top: 0;
			left: -250px;
			z-index: 1000;
			background-color: #fff;
			border-right: 1px solid #000;
			width: 250px;
			height: 100%;
			padding-bottom: 10px;
			overflow-y: auto;
			display: flex;
			flex-direction: column;
			transition: all 0.5s ease-out;

			&.active{
				left: 0;
			}

			.bloco-superior{
				order: 2;
				text-align: center;
				padding: 0 5px;

				.msg,
				figure{
					display: none;
				}
				.midia + .midia{
					margin-top: 15px;
				}
				.midia{
					display: flex;
					flex-direction: column;
					align-items: center;
					color: #000;
					line-height: 1;

					i.fas{
						margin-bottom: 5px;
					}
				}
			}
			.menu{
				order: 1;
				margin-bottom: 30px;
				.active a{
					background-color: $azul-claro;
					color: #fff;
				}
				a{
					display: block;
					padding: 10px;
					font-size: 14px;

					&:hover{
						background-color: $azul-claro;
						color: #fff;
					}
				}
			}

		}
		//.main-navigation
	}
}
// .topo
