body{
	font-family: $ws-regular;
}
body,
html{
	min-height: 100vh;
	width: 100%;
}
#app{
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.wrapper{
		flex-grow: 1;
	}
}
h1, h2, h3, h4, h5, h6, p, figure{
	margin-bottom: 0;
}
textarea{
	resize: none;
}
a{
	color: inherit;

	&:hover,
	&:focus{
		color: inherit;
		text-decoration: none;
	}
}
.bg-menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	display: none;
	background-color: rgba(0,0,0,0.6);
}
.desc{
	font-size: 16px;
	color: #535353;
	line-height: 20px;

	a:hover{
		text-decoration: underline;
	}
	img{
		max-width: 100%;
		height: auto;
	}
	iframe{
		max-width: 100%;
	}

	&.editor{
		p + p{
			margin-top: 1em;
		}
	}
}
.redes-sociais{
	list-style-type: none;
	margin-bottom: 0;
	padding-left: 0;
	display: flex;
	align-items: center;
}
.titulo-1{
	font-size: 42px;
	line-height: 1.2;
	color: #545454;
	text-align: center;
	font-family: $ws-light;
	margin-bottom: 10px;
}
.titulo-opaco{
	font-family: $ws-black;
}
.titulo-2{
	font-size: 18px;
	font-family: $ws-extrabold;
	line-height: 1;
	text-transform: uppercase;
}
.video-mensal{
	padding-bottom: percentage(500/1080);
}
.box-video{
	width: 100%;
	max-width: 1050px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	overflow: hidden;

	.img-video{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		cursor: pointer;

		&:target{
			display: none;
		}

		&:before{
			content: "\f04b";
			position: absolute;
			font-family: 'Font Awesome 5 Free';
		    font-weight: 900;
		    top: 50%;
		    left: 50%;
		    transform: translate(-50%, -50%);
		    z-index: 3;
		    border-radius: 50%;
		    background-color: rgba(255,255,255,0.8);
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    color: #000;
		}

		&.off-display{
			display: none;
		}
	}

	@include media-breakpoint-up(lg) {
		.img-video{
			&:before{
				width: 100px;
				height: 100px;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.img-video{
			&:before{
				width: 50px;
				height: 50px;
				font-size: 12px;
			}
		}
	}
}
// .box-video

// .box-video
// 	figure.img-video
// 		img(src="http://placehold.it/1080x500/0f0/000" alt="Nome do vídeo" title="Nome do vídeo").img-fluid
// 	.embed-responsive.video-mensal
// 		iframe(width="1080", height="500", src="https://www.youtube.com/embed/TsM2kmDKf_0", frameborder="0", allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture", allowfullscreen)