.paginacao{
	list-style-type: none;
	margin-bottom: 0;
	padding-left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	li +  li{
		margin-left: 5px;
	}
	.active a{
		background-color: $azul;
		color: #fff;
		pointer-events: none;
	}
	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 32px;
		height: 28px;
		border-radius: 1px;
		background-color: #ededed;
		color: #333;
		font-size: 14px;
		line-height: 1;

		&:hover{
			background-color: #ddd;
		}
	}
}
