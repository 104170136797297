.btn-pill{
	border-radius: 2rem;
}
.btn-padrao{
	width: 210px;
	max-width: 100%;
	height: 64px;
	border-radius: 32px;
	background-color: $azul-claro;
	color: #fff;
	text-align: center;
	padding: 5px;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-family: $ws-bold;
	transition: all 0.3s linear;

	&:hover{
		background-color: darken($azul-claro, 10%);
		color: #fff;
	}
}