.rodape{
	box-shadow: 0 0 10px rgba(0,0,0,0.2);

	.conteudo{
		padding: 30px 0;

		.box-bandeira-texto{
			.bandeira{
				max-width: 160px;
				min-width: 160px;
			}
			.texto{
				.frase{
					font-size: 13px;
					color: #535353;
					line-height: 17px;
				}
				.redes-sociais{
					margin-top: 10px;

					li + li{
						margin-left: 5px;
					}
					a{
						width: 44px;
						height: 44px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #ddd;
						color: #a5a5a5;
						font-size: 18px;
						border: 1px solid transparent;
						border-radius: 50%;
						transition: all 0.3s linear;

						&:hover{
							border-color: $azul-claro;
							background-color: #fff;
							color: $azul-claro;
						}
					}
				}
			}
		}
		// .box-bandeira-texto

		.midia{
			margin-bottom: 15px;
			i.fas{
				width: 44px;
				min-width: 44px;
				height: 38px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 3px;
				color: #000;
				background-color: #ddd;
			}
			.box{
				color: #4d4d4d;
				font-size: 14px;
				line-height: 18px;

				.tipo{
					text-transform: uppercase;
				}
				a{
					word-break: break-all;
				}
				a:hover{
					text-decoration: underline;
				}
			}
		}

		.contador{
			font-size: 18px;
			color: #000;
			font-family: $ws-extrabold;
			line-height: 1;
		}

		.menu{
			list-style-type: none;
			margin-bottom: 0;
			padding-left: 0;
			line-height: 1;
		
			.active a{
				color: $azul-claro;
			}
			a{
				font-size: 14px;
				color: #4d4d4d;
				line-height: 18px;
				display: inline-block;
				transition: all 0.3s linear;

				&:hover{
					color: $azul-claro;
				}
			}
		}

		@include media-breakpoint-only(xl) {
			.midia{
				display: flex;
				i.fas{
					margin-right: 10px;
				}
			}
			.col-xl-3{
				position: relative;

				&:before{
					content: '';
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: -3px;
					width: 5px;
					height: 120px;
					background-color: #e4e4e4;
				}
			}
		}
		@include media-breakpoint-down(lg) {
			.col-xl-6{
				margin-bottom: 30px;
			}
		}
		@include media-breakpoint-up(md) {
			.midia{
				display: flex;
				i.fas{
					margin-right: 10px;
				}
			}
			.box-bandeira-texto{
				display: flex;
				.bandeira{
					margin-right: 30px;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			.col-xl-3 + .col-xl-3{
				margin-top: 30px;
			}
			.box-bandeira-texto{
				text-align: center;
				.bandeira{
					margin-right: auto;
					margin-left: auto;
					margin-bottom: 10px;
				}
				.redes-sociais{
					justify-content: center;
				}
			}
			.midia{
				text-align: center;
				i.fas{
					margin-right: auto;
					margin-left: auto;
					margin-bottom: 5px;
				}
			}
			.contador{
				text-align: center;
			}
			.menu{
				text-align: center;

				a{
					font-size: 16px;
				}
			}
		}
	}
	// .conteudo
	.autorais{
		background-color: $azul;
		color: #fff;
		text-align: center;
		padding: 10px 0;
		line-height: 16px;
		font-size: 10px;

		a{
			font-weight: bold;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-top: 5px;

			svg{
				margin-left: 5px;
				font-size: 24px;
			}
		}
	}
	// .autorais
}
// .rodape