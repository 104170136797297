.internas{
	.pag-header{
		background-color: $azul;
		color: #fff;
		padding: 20px 0;

		.pag-header-nav{
			list-style-type: none;
			margin-bottom: 0;
			padding-left: 0;
			line-height: 1;
			text-transform: uppercase;
		}

		@include media-breakpoint-up(lg) {
			.pag-header-nav{
				display: flex;
				align-items: center;
				justify-content: flex-end;
				font-size: 14px;

				li + li{
					position: relative;
					display: flex;

					&:before{
						content: '-';
						position: relative;
						display: block;
						margin: auto 10px;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			.pag-header-nav{
				text-align: center;
				font-size: 16px;

				li + li{
					margin-top: 5px;
				}
			}
		}
	}
	//.pag-header

	.pag-body{
		padding: 60px 0;

		.titulo-1{
			font-size: 32px;
		}

		.listagem-videos{
			.card-videos{
				margin-top: 30px;
				.titulo-2{
					color: #000;
					margin: 10px 0 5px 0;
					font-size: 16px;
				}
				.texto{
					color: #000;
				}
			}
		}
		// .listagem-videos

		.box-postagens + .box-postagens{
			border-top: 1px solid #ededed;
			padding-top: 40px;
		}
		.box-postagens{
			margin-bottom: 40px;
			

			.titulo-2{
				color: #000;
				margin-bottom: 10px;
			}
			.data{
				font-size: 16px;
				color: #111;
				display: flex;
				align-items: center;
				margin: 10px 0 20px 0;
				i.far{
					margin-right: 5px;
				}
			}
			h2.titulo-2{
				font-size: 16px;
			}
			.opiniao{
				font-size: 14px;
				color: #535353;
				line-height: 18px;

				p + p{
					margin-top: 1em;
				}
			}

			@include media-breakpoint-down(md) {
				figure.col-lg-4{
					margin-bottom: 20px;
				}
			}
		}
		// .box-postagens

		&.postagem-diaria{
			text-align: center;

			.titulo-2{
				color: #000;
				margin-bottom: 10px;
			}
			.data{
				font-size: 16px;
				color: #111;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 10px 0 40px 0;
				i.far{
					margin-right: 5px;
				}
			}
			h2.titulo-2{
				font-size: 16px;
			}
			.opiniao{
				font-size: 14px;
				color: #535353;
				line-height: 18px;

				p + p{
					margin-top: 1em;
				}
			}
		}
		// &.postagem-diaria

		&.pag-utilidade{
			.titulo-2{
				width: 100%;
				max-width: 530px;
				margin: 0 auto;
				text-align: center;
				color: #000;
			}
			.card-links{
				margin-top: 40px;
				color: #282828;
				font-size: 15px;
				line-height: 19px;
				display: flex;
				align-items: center;

				.icone{
					margin-right: 10px;
					width: 56px;
					min-width: 56px;
					height: 56px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 50%;
					box-shadow: 0 0 6px rgba(0,0,0,0.4);

					.clip{
						width: 27px;
						height: 27px;
						display: block;
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						background-image: url('../images/clip.png');
					}
				}
				a{
					word-break: break-all;
					&:hover{
						text-decoration: underline;
					}
				}				
			}
			// .card-links
		}
		// &.pag-utilidade

		&.pag-recado{
			.titulo-2{
				width: 100%;
				max-width: 420px;
				margin: 0 auto 30px auto;
				text-align: center;
				color: #000;
			}
			.form-estilos{
				box-shadow: 0 0 6px rgba(0,0,0,0.4);
				margin-bottom: 30px;
			}
			select.form-control,
			input.form-control{
				height: 56px;
			}
			.form-control{
				border-radius: 0;
				background-color: #ededed;
				font-size: 16px;
				color: #000;
				border-color: transparent;

				&::placeholder{
					color: #000;
				}
				&:focus{
					border-color: $azul;
					box-shadow: none;
				}
			}
			select.form-control{
				-webkit-appearance: none;  /* Remove estilo padrão do Chrome */
				-moz-appearance: none; /* Remove estilo padrão do FireFox */
				appearance: none; /* Remove estilo padrão do FireFox*/
				background: url('../images/select.png') no-repeat #ededed;  /* Imagem de fundo (Seta) */
				background-position: 95% center;  /*Posição da imagem do background*/
			}
			.btn-padrao{
				margin: 20px auto 0 auto;
				border: none; 
			}
			.box-depoimentos{
				margin-top: 30px;
				padding-top: 40px;
				border-top: 2px solid #ededed;
				text-align: center;
				color: #797979;

				.nome{
					font-size: 18px;
					text-transform: uppercase;
					font-family: $ws-bold;
					margin-bottom: 10px;
				}
				.depoimento{
					font-size: 15px;
					line-height: 19px;
					width: 890px;
					max-width: 100%;
					margin: 0 auto 10px auto;
				}
				#carouselDepoimentos{
					position: relative;
					padding: 0 30px;

					&:before{
						content: '';
						position: absolute;
						width: 203px;
						height: 160px;
						background-size: 100% 100%;
						background-position: center center;
						background-repeat: no-repeat;
						background-image: url('../images/aspas2.png');
						top: 50%;
						transform: translateY(-50%);
						left: 0;
					}
					.controles{
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 100%;

						.prev,
						.next{
							position: absolute;
							top: 0;
							transform: translateY(-50%);
							font-size: 32px;
							line-height: 1;
							color: #000;
						}
						.prev{
							left: 0;
						}
						.next{
							right: 0;
						}
					}
				}
			}
			// .box-depoimentos

			@include media-breakpoint-up(lg) {
				.form-estilos{
					padding: 40px 60px 30px 60px;
				}
				.box-depoimentos{
					#carouselDepoimentos{
						
					}
				}
				// .box-depoimentos
			}
			@include media-breakpoint-down(md) {
				.form-estilos{
					padding: 30px 15px;
				}
			}
			@include media-breakpoint-up(sm) {
				.col-sm-9{
					padding-right: 5px;
				}
				.col-sm-3{
					padding-left: 5px;
				}
			}
		}
		// &.pag-recado

		&.pag-obras{
			.titulo-2{
				width: 100%;
				max-width: 500px;
				margin: 0 auto 15px auto;
				text-align: center;
				color: #000;
			}
			.desc{
				text-align: center;
				margin-bottom: 30px;
			}
			.card-obras{
				width: 100%;
				max-width: 255px;
				margin: 0 auto 30px auto;

				.nome{
					font-size: 16px;
					text-transform: uppercase;
					line-height: 18px;
					color: #181818;
					font-family: $ws-extrabold;
					margin: 5px 0;
				}
				.botao{
					display: block;
					background-color: #5995b4;
					color: #fff;
					text-align: center;
					padding: 15px 5px;
					font-size: 14px;
					line-height: 14px;
					text-transform: uppercase;
				}
			}
			// .card-obras

			.owl-carousel{
				.owl-nav{
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 100%;

					.owl-prev,
					.owl-next{
						position: absolute;
						top: 0;
						transform: translateY(-50%);
						font-size: 32px;
						line-height: 1;
						color: #000;
					}
				}
			}

			#owlObras{
				margin-top: 30px;
				.card-obras{
					margin-bottom: 0;

					.botao{
						background-color: $azul;
					}
				}
			}

			.box-parceiros{
				margin: 40px 0;
				padding: 30px 0;
				border-top: 2px solid #ededed;
				border-bottom: 2px solid #ededed;

				.card-parceiros{
					width: 100%;
					max-width: 160px;
					margin-left: auto;
					margin-right: auto;
				}
			}
			// .box-parceiros

			.box-final{
				.desc{
					margin-bottom: 0;
				}
			}

			@include media-breakpoint-up(sm) {
				.owl-carousel{
					.owl-nav{
						.owl-prev{
							left: -25px;
						}
						.owl-next{
							right: -25px;
						}
					}
				}
			}
			@include media-breakpoint-only(xs) {
				#owlObras{
					.owl-nav{
						max-width: 300px;
						.owl-prev{
							left: 0px;
						}
						.owl-next{
							right: 0px;
						}
					}
				}
				#owlParceiros{
					.owl-nav{
						max-width: 200px;
						.owl-prev{
							left: 0px;
						}
						.owl-next{
							right: 0px;
						}
					}
				}
			}
		}
		// &.pag-obras

	}
	// .pag-body
}
// .internas