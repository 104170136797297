.comentarios__area{}

.comentarios__header{
	display: flex;

	h1{
		font-family: 'WorkSans-Bold';
		font-size: 18px;
		color: #181818;
		flex-grow: 1;
	}
}

.comentarios__filtro{
	margin-bottom: 50px;

	.btn{
		padding: 1rem 1.5rem;
		background: #EEEEEE;
		display: flex;
		align-items: center;
		border-radius: 0;
		overflow: hidden;
		perspective: 3px;
		border: none;
		box-shadow: none !important;

		span{
			display: inline-block;
			margin: 0 15px;
		}

		.fas{
			margin-left: auto;
			display: inline-block;
			transition: transform 0.4s linear;
		}

		&:before,
		&:after{
			content:'';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
			background: rgba(#000,.05);
			clip-path: circle(var(--w, 0%) at var(--x) var(--y));
			transition: all 0.3s linear;
		}

		&:before{
			--x: 0;
			--y: 100%;
		}

		&:after{
			--x: 100%;
			--y: 100%;
		}

		&:hover,
		&[aria-expanded="true"]{
			--w: 150%;
		}
	}

	.dropdown-menu{
		min-width: 100%;
		border-radius: 0;
		padding-top: 0;
		padding-bottom: 0;

		li + li{
			border-top: 1px solid rgba(#000,.1);
		}

		a{
			display: block;
			padding: 10px 15px;
			transition: all 0.4s linear;

			&:hover{
				text-decoration: none;
			}
		}

		li{

			&:not(.selected):hover a{
				background: rgba(#000,.05);
			}

			&.selected{
				background: $primary;
				color: #FFF;
			}
		}
	}

	.comentarios__select {
		position: fixed;
		bottom: 300vh;
		clip-path: polygon(0 0, 0 0, 0 0)
	}
}


.comentario{
	margin-bottom: 1rem;
	color: #797979;

	.comentario{
		margin-left: 1rem;
		margin-top: 10px;

		.comentario__header,
		.comentario__depoimento,
		.comentario__interacao{
			border-left: 4px solid #000;
			padding-left: 1rem;
		}
	}
}

.comentario__header{
	display: grid;
	grid-template-columns: repeat(2, minmax(130px, 50%));
	grid-column-gap: 5px;
	padding-bottom: 15px;
	font-size: 16px;
	white-space: nowrap;

	span{
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
	}

	.nome{
		max-width: calc(100% - 0.5em);
	}

	.cidade{
		margin-right: 5px;
		max-width: calc(100% - #{toEm(54, 16)});
	}

	.uf{
		margin-left: 5px;
	}
}

.comentario__autor,
.comentario__origem {
	font-family: 'WorkSans-ExtraBold';
	width: 100%;
}

.comentario__datatime{
	font-weight: 600;
}

.comentario__interacao{
	.btn{
		padding: 0.75rem 0;
		transition: color 0.4s linear;

		&:hover{
			color: #00A4D3;
		}

		& + .btn {
			margin-left: 2rem;
		}
	}
}

.comentarios__box{
	max-height: 400px;
	overflow: auto;
}
