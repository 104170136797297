.biografia{
	background-color: #ededed;

	.conteudo{
		.redes-sociais{
			margin-top: 20px;
			margin-bottom: 20px;
			justify-content: center;

			li + li{
				margin-left: 5px;
			}
			a{
				width: 44px;
				height: 44px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #a5a5a5;
				font-size: 18px;
				border: 1px solid transparent;
				border-radius: 50%;
				transition: all 0.3s linear;

				&:hover{
					border-color: $azul-claro;
					color: $azul-claro;
				}
			}
		}
	}
	// .conteudo

	.opiniao-video{
		padding-bottom: 30px;
		background-color: $azul;

		.titulo-opaco{
			color: rgba(0,0,0,0.1);
		}
		.titulo-2{
			color: #fff;
			margin-bottom: 5px;
		}
		.desc{
			font-size: 14px;
			line-height: 18px;
			color: #fff;
		}
		.btn-padrao{
			margin: 30px auto 0 auto;
		}
	}
	// .opiniao-video

	@include media-breakpoint-only(xl) {
		.conteudo{
			padding-top: 80px;

			.box-video{
				position: relative;
				margin-bottom: -240px;
			}
		}
		.opiniao-video{
			padding-top: 270px;
			.titulo-opaco{
				font-size: 86px;
				line-height: 66px;
			}
		}
	}
	@include media-breakpoint-only(lg) {
		.conteudo{
			padding-top: 60px;

			.box-video{
				position: relative;
				margin-bottom: -190px;
			}
		}
		.opiniao-video{
			padding-top: 220px;
			.titulo-opaco{
				font-size: 70px;
				line-height: 60px;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		position: relative;
		z-index: 2;
		overflow: hidden;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			min-width: 1920px;
			height: 1000px;
			z-index: -1;
			background-repeat: no-repeat;
			background-position: right top;
			background-image: url('../images/bg-biografia.jpg');
		}

		.conteudo{
			.box-video{
				border: 10px solid #ededed;
			}
		}
		.opiniao-video{
			.titulo-2{
				text-align: right;
			}
			.desc{
				text-align: right;
			}
		}
	}
	@include media-breakpoint-down(md) {
		.conteudo{
			padding-top: 30px;
			padding-bottom: 30px;
			.box-video{
				border: 10px solid $azul;
			}
		}
		.opiniao-video{
			text-align: center;
			padding: 30px;

			.titulo-opaco{
				line-height: 1;
			}
			.titulo-2{
				margin: 20px 0;
			}
		}
	}
}
// .biografia

.postagem-diaria{
	padding: 40px 0 30px 0;

	.content{
		.titulo-opaco{
			color: rgba(0,0,0,0.1);
			margin-bottom: 20px;
		}
		.box-noticia{
			.nome{
				font-size: 18px;
				color: #000;
				font-family: $ws-extrabold;
				line-height: 20px;
				text-transform: uppercase;
				margin-bottom: 10px;
			}
			.data{
				font-size: 16px;
				color: #111;
				display: flex;
				align-items: center;
				margin-top: 5px;
				i.far{
					margin-right: 5px;
				}
			}
		}
		// .box-noticia
	}
	// .content

	.box-opiniao{
		background-color: #0c96c5;
		position: relative;
		z-index: 2;

		&:before{
			content: '';
			position: absolute;
			width: 155px;
			height: 115px;
			background-size: 100% 100%;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: url('../images/aspas.png');
		}

		.titulo-2{
			color: #fff;
			margin-bottom: 5px;
		}
		.desc{
			color: #fff;
			font-size: 14px;
			line-height: 18px;
		}
	}
	// .box-opiniao

	@include media-breakpoint-only(xl) {
		.content{
			padding-bottom: 200px;
			.titulo-opaco{
				font-size: 96px;
				line-height: 72px;
				text-align: right;
			}
		}
		.box-opiniao{
			padding: 60px 40px 60px 170px;
			width: 100%;
			max-width: 940px;
			margin-left: auto;
			margin-top: -170px;

			&:before{
				top: 50px;
				left: 50px;
			}
		}
		// .box-opiniao
	}
	@include media-breakpoint-only(lg) {
		.content{
			padding-bottom: 200px;
			.titulo-opaco{
				font-size: 40px;
				line-height: 1;
				text-align: right;
			}
		}
		.box-opiniao{
			padding: 40px 40px 40px 170px;
			width: 100%;
			max-width: 800px;
			margin-left: auto;
			margin-top: -170px;

			&:before{
				top: 40px;
				left: 40px;
			}
		}
		// .box-opiniao
	}
	@include media-breakpoint-up(lg) {
		position: relative;
		z-index: 2;
		overflow: hidden;

		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			min-width: 1920px;
			height: 495px;
			z-index: -1;
			background-repeat: no-repeat;
			background-position: left top;
			background-image: url('../images/bg-postagem.png');
		}
	}
	@include media-breakpoint-down(md) {
		figure.col-lg-6{
			text-align: center;
		}
		.content{
			padding: 30px 15px;
			.titulo-opaco{
				font-size: 40px;
				line-height: 1;
				text-align: center;
			}
		}
		.box-opiniao{
			padding: 100px 15px 30px 15px;

			&:before{
				top: 15px;
				left: 15px;
			}
		}
	}
}
// .postagem-diaria
.segura__download__btn{
	display: flex;
	justify-content: center;
	padding: 60px 0px;
}
