@font-face {
    font-family: 'WorkSans-Black';
    font-display: auto;
    src: url('../fonts/WorkSans-Black/WorkSans-Black.eot');
    src: url('../fonts/WorkSans-Black/WorkSans-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/WorkSans-Black/WorkSans-Black.woff2') format('woff2'),
        url('../fonts/WorkSans-Black/WorkSans-Black.woff') format('woff'),
        url('../fonts/WorkSans-Black/WorkSans-Black.ttf') format('truetype'),
        url('../fonts/WorkSans-Black/WorkSans-Black.svg#WorkSans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}
@font-face {
    font-family: 'WorkSans-Bold';
    font-display: auto;
    src: url('../fonts/WorkSans-Bold/WorkSans-Bold.eot');
    src: url('../fonts/WorkSans-Bold/WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/WorkSans-Bold/WorkSans-Bold.woff2') format('woff2'),
        url('../fonts/WorkSans-Bold/WorkSans-Bold.woff') format('woff'),
        url('../fonts/WorkSans-Bold/WorkSans-Bold.ttf') format('truetype'),
        url('../fonts/WorkSans-Bold/WorkSans-Bold.svg#WorkSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'WorkSans-ExtraBold';
    font-display: auto;
    src: url('../fonts/WorkSans-ExtraBold/WorkSans-ExtraBold.eot');
    src: url('../fonts/WorkSans-ExtraBold/WorkSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/WorkSans-ExtraBold/WorkSans-ExtraBold.woff2') format('woff2'),
        url('../fonts/WorkSans-ExtraBold/WorkSans-ExtraBold.woff') format('woff'),
        url('../fonts/WorkSans-ExtraBold/WorkSans-ExtraBold.ttf') format('truetype'),
        url('../fonts/WorkSans-ExtraBold/WorkSans-ExtraBold.svg#WorkSans-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'WorkSans-Light';
    font-display: auto;
    src: url('../fonts/WorkSans-Light/WorkSans-Light.eot');
    src: url('../fonts/WorkSans-Light/WorkSans-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/WorkSans-Light/WorkSans-Light.woff2') format('woff2'),
        url('../fonts/WorkSans-Light/WorkSans-Light.woff') format('woff'),
        url('../fonts/WorkSans-Light/WorkSans-Light.ttf') format('truetype'),
        url('../fonts/WorkSans-Light/WorkSans-Light.svg#WorkSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'WorkSans-Regular';
    font-display: auto;
    src: url('../fonts/WorkSans-Regular/WorkSans-Regular.eot');
    src: url('../fonts/WorkSans-Regular/WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/WorkSans-Regular/WorkSans-Regular.woff2') format('woff2'),
        url('../fonts/WorkSans-Regular/WorkSans-Regular.woff') format('woff'),
        url('../fonts/WorkSans-Regular/WorkSans-Regular.ttf') format('truetype'),
        url('../fonts/WorkSans-Regular/WorkSans-Regular.svg#WorkSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}